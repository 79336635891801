var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.inventories,"items-per-page":10,"search":_vm.search,"header-props":{ sortIcon: null },"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-2",attrs:{"color":"accent","append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(inventory,index2){return _c('tr',{key:index2},[_c('td',[_vm._v(_vm._s(inventory.name))]),_c('td',[_vm._v(_vm._s(inventory.description))]),_c('td',[_vm._v(_vm._s(inventory.part_number))]),_c('td',[_vm._v(_vm._s(inventory.bin_location))]),_c('td',[_vm._v(_vm._s(inventory.cost))]),_c('td',[_vm._v(_vm._s(inventory.retail_price))]),_c('td',[_vm._v(_vm._s(inventory.qty_on_hand))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(inventory.created_at)))]),_c('td',[_c('v-layout',{staticClass:"mx-1"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.currentUser.role_id == 1),expression:"currentUser.role_id == 1"}],staticClass:"mx-1",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'editpart_inventory', params: { id: inventory.inv_id } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.currentUser.role_id == 1),expression:"currentUser.role_id == 1"}],attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteInventoryPartList(inventory.inv_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForeverOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1),_vm._l((_vm.permission),function(permission,index){return _c('div',{key:index},_vm._l((permission.usermenus),function(usermenu,index1){return _c('div',{key:index1},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(usermenu.update == 1 && permission.role_id == 3),expression:"usermenu.update == 1 && permission.role_id == 3"}],staticClass:"mx-1",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'editpart_inventory', params: { id: inventory.inv_id } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(usermenu.delete == 1 && permission.role_id == 3),expression:"usermenu.delete == 1 && permission.role_id == 3"}],attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteInventoryPartList(inventory.inv_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForeverOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)}),0)})],2)],1)])}),0)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.getInventoryItems}},[_vm._v(" Reset ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }