<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="vendors"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="vendor_name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(vendor, index1) in items" :key="index1">
            <td>{{ vendor.vendor_name }}</td>
            <td>{{ vendor.account_number }}</td>
            <td>
              <v-icon class="mr-1">{{ icons.mdiEmail }}</v-icon
              >{{ vendor.email }}
            </td>
            <td>
              <div v-for="phone in vendor.phones" :key="phone.phone_id">
                <span
                  ><v-icon class="mr-1">{{ icons.mdiPhone }}</v-icon
                  >{{ phone.tel_number }}</span
                >
              </div>
            </td>
            <td>{{ formatDate(vendor.created_at) }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    @click.prevent="
                      $router.push({
                        name: 'editvendor',
                        params: { id: vendor.vendor_id },
                      })
                    "
                    class="mx-1"
                    fab
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    dark
                  >
                    <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.prevent="deleteVendor(vendor.vendor_id)"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                    dark
                  >
                    <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getVendors"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiPhone,
  mdiEmail,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      headers: [
        {
          text: 'Vendor Name',
          value: 'vendor_name',
        },
        { text: 'Account Number', value: 'account_number' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'tel_number' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      vendors: [],
      search: '',
      icons: {
        mdiAccountMultiple,
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiPhone,
        mdiEmail,
      },
    }
  },

  created: function() {
    this.getVendors()
  },

  methods: {
    getVendors: function() {
      this.$store
        .dispatch('vendor/fetchVendors')
        .then(response => {
          this.vendors = this.fetchVendors
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteVendor: function(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
            this.$store
              .dispatch(`vendor/deleteVendor`, { vendor_id: id })
              .then(response => {
                this.getVendors()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchVendors: 'vendor/fetchVendors',
    }),
  },
}
</script>
