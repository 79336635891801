<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiArchiveArrowDown }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-btn
        v-if="activeTabs == 'Part'"
        color="accent"
        v-show="currentUser.role_id == 1"
        rounded
        @click.prevent="$router.push({ name: 'addnewpart_inventory' })"
      >
        <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
        <span>Add Part</span>
      </v-btn>
      <v-btn
        v-if="activeTabs == 'Tire'"
        color="accent"
        v-show="currentUser.role_id == 1"
        rounded
        @click.prevent="$router.push({ name: 'addnewtire_inventory' })"
      >
        <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
        <span>Add Tire</span>
      </v-btn>
      <v-btn
        v-if="activeTabs == 'Labor'"
        color="accent"
        rounded
        v-show="currentUser.role_id == 1"
        @click.prevent="$router.push({ name: 'addnewlabor_inventory' })"
      >
        <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
        <span>Add Labor</span>
      </v-btn>
      <v-btn
        v-if="activeTabs == 'Vendor'"
        color="accent"
        v-show="currentUser.role_id == 1"
        rounded
        @click.prevent="$router.push({ name: 'addnewvendor' })"
      >
        <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
        <span>Add Vendor</span>
      </v-btn>

      <div v-for="(permission, index) in permission" :key="index">
        <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
          <v-btn
            v-if="activeTabs == 'Part'"
            color="accent"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnewpart_inventory' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Part</span>
          </v-btn>
          <v-btn
            v-if="activeTabs == 'Tire'"
            color="accent"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnewtire_inventory' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Tire</span>
          </v-btn>
          <v-btn
            v-if="activeTabs == 'Labor'"
            color="accent"
            rounded
            v-show="usermenu.create == 1 && permission.role_id == 3"
            @click.prevent="$router.push({ name: 'addnewlabor_inventory' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Labor</span>
          </v-btn>

          <v-btn
            v-if="activeTabs == 'Vendor'"
            color="accent"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnewvendor' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Vendor</span>
          </v-btn>
        </div>
      </div>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs>
      <v-tab @click.prevent="activeTab('Part')">
        <v-icon left>{{ icons.mdiTools }}</v-icon>
        Parts
      </v-tab>
      <v-tab @click.prevent="activeTab('Tire')">
        <v-icon left>{{ icons.mdiCarInfo }}</v-icon>
        Tires
      </v-tab>
      <v-tab @click.prevent="activeTab('Labor')">
        <v-icon left> {{ icons.mdiAccountHardHat }} </v-icon>
        Labors
      </v-tab>
      <v-tab @click.prevent="activeTab('Vendor')">
        <v-icon left> {{ icons.mdiAccountMultiple }} </v-icon>
        Vendors
      </v-tab>
      <v-tab @click.prevent="activeTab('Fee')">
        <v-icon left> {{ icons.mdiCash }} </v-icon>
        Fees
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <Parts />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <Tires />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <Labor />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <Vendor />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Parts from '@/components/inventory/Part/Parts.vue'
import Tires from '@/components/inventory/Tire/Tires.vue'
import Labor from '@/components/inventory/Labor/Labor.vue'
import Vendor from '@/components/inventory/Vendor/Vendor.vue'

import {
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiAccountSupervisor,
  mdiCar,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiBell,
  mdiCalendarAccount,
  mdiClose,
  mdiArchiveArrowDown,
  mdiTools,
  mdiCarInfo,
  mdiAccountHardHat,
  mdiAccountMultiple,
  mdiCash,
} from '@mdi/js'

export default {
  components: { Parts, Tires, Labor, Vendor },

  created: function() {
    this.getAuthorizations()
    this.activeTab('Part')
  },

  data() {
    return {
      activeTabs: 'Part',
      permission: {},

      icons: {
        mdiAccountTie,
        mdiCardAccountDetails,
        mdiAccountSupervisor,
        mdiCar,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiBell,
        mdiCalendarAccount,
        mdiClose,
        mdiArchiveArrowDown,
        mdiTools,
        mdiCarInfo,
        mdiAccountHardHat,
        mdiAccountMultiple,
        mdiCash,
      },
    }
  },
  methods: {
    activeTab: function(selectedTab) {
      this.activeTabs = selectedTab
      this.getAuthorizations()
    },

    getAuthorizations: function() {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 4,
          submenu_id: '',
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>
<style></style>
