<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="inventories"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(inventory, index2) in items" :key="index2">
            <td>{{ inventory.name }}</td>
            <td>{{ inventory.description }}</td>
            <td>{{ inventory.part_number }}</td>
            <td>{{ inventory.bin_location }}</td>
            <td>{{ inventory.cost }}</td>
            <td>{{ inventory.retail_price }}</td>
            <td>{{ inventory.qty_on_hand }}</td>
            <td>{{ formatDate(inventory.created_at) }}</td>
            <td>
              <v-layout class="mx-1">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-show="currentUser.role_id == 1"
                        @click.prevent="$router.push({ name: 'editpart_inventory', params: { id: inventory.inv_id } })"
                        class="mx-1"
                        fab
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        dark
                      >
                        <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.prevent="deleteInventoryPartList(inventory.inv_id)"
                        color="error"
                        v-show="currentUser.role_id == 1"
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        dark
                      >
                        <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
                <div v-for="(permission, index) in permission" :key="index">
                  <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          v-show="usermenu.update == 1 && permission.role_id == 3"
                          @click.prevent="
                            $router.push({ name: 'editpart_inventory', params: { id: inventory.inv_id } })
                          "
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                          fab
                          x-small
                          dark
                        >
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click.prevent="deleteInventoryPartList(inventory.inv_id)"
                          color="error"
                          v-show="usermenu.delete == 1 && permission.role_id == 3"
                          fab
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          dark
                        >
                          <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getInventoryItems"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiAccountTie, mdiMagnify, mdiDotsVertical, mdiPlus, mdiPencilOutline, mdiDeleteForeverOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  created: function() {
    this.getInventoryItems()
    this.getAuthorizations()
  },

  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Part Number', value: 'part_number' },
        { text: 'Bin/Location', value: 'bin_location' },
        { text: 'Cost', value: 'cost' },
        { text: 'Retail Price', value: 'retail_price' },
        { text: 'Qty on Hand', value: 'qty_on_hand' },

        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      inventories: [],
      permission: {},
      search: '',
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
      },
    }
  },
  methods: {
    getInventoryItems: function() {
      this.$store
        .dispatch('inventory/fetchInventoryItems', {
          item_type: 'Part',
        })
        .then(response => {
          this.inventories = this.fetchInventoryItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function() {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 4,
          submenu_id: '',
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteInventoryPartList(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inventory/deleteInventoryItem`, {
                inv_id: id,
              })
              .then(response => {
                this.getInventoryItems()
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchInventoryItems: 'inventory/fetchInventoryItems',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style></style>
