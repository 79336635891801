<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="inventories"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(inventory, index1) in items" :key="index1">
            <td>
              <div v-for="(brand, index2) in inventory.brands" :key="index2">
                <span
                  ><v-icon size="20" color="primary" @click.prevent="editBrandModal(brand)">{{
                    icons.mdiPencil
                  }}</v-icon>
                  {{ brand.name }}
                </span>
              </div>
            </td>
            <td>{{ inventory.model }}</td>
            <td>{{ inventory.sizes }}</td>
            <td>{{ inventory.description }}</td>
            <td>{{ inventory.part_number }}</td>
            <td>{{ inventory.cost }}</td>
            <td>{{ inventory.retail_price }}</td>
            <td>{{ inventory.bin_location }}</td>
            <td>{{ inventory.qty_on_hand }}</td>
            <td>{{ formatDate(inventory.created_at) }}</td>
            <td>
              <v-menu offset-y bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn light icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click.prevent="$router.push({ name: 'edittire_inventory', params: { id: inventory.inv_id } })"
                  >
                    <v-list-item-icon>
                      <v-icon size="20" color="primary" class="mx-1" v-text="icons.mdiPencil"></v-icon>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item @click.prevent="deleteInventoryTireList(inventory.inv_id)">
                    <v-list-item-icon>
                      <v-icon size="20" color="error" class="mx-1" v-text="icons.mdiClose"></v-icon>
                      <v-list-item-title>Remove</v-list-item-title>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getInventoryItems"> Reset </v-btn>
      </template>
    </v-data-table>

    <edit-brand
      :dialog.sync="showEditBrandDialog"
      :brand="brand"
      @open-dialog="showEditBrandDialog = true"
      @close-dialog="showEditBrandDialog = false"
    />
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import EditDialog from '@/views/settings/brand/EditBrand.vue'
import {
  mdiAccountTie,
  mdiTire,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiClose,
  mdiPencil,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    'edit-brand': EditDialog,
  },
  created: function() {
    this.getInventoryItems()
    window.mitt.on('loadInventoryItems', () => {
      this.getInventoryItems()
    })
  },

  data() {
    return {
      headers: [
        { text: 'Brand', value: 'brand' },
        { text: 'Model', value: 'model' },
        { text: 'Size', value: 'sizes' },
        { text: 'Description', value: 'description' },
        { text: 'Part Number', value: 'part_number' },
        { text: 'Cost', value: 'cost' },
        { text: 'Retail Price', value: 'retail_price' },
        { text: 'Bin/Location', value: 'bin_location' },
        { text: 'Qty on Hand', value: 'qty_on_hand' },

        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      inventories: [],
      brand: {},
      search: '',
      showNewBrandDialog: false,
      showEditBrandDialog: false,
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiClose,
        mdiPencil,
      },
    }
  },
  methods: {
    getInventoryItems: function() {
      this.$store
        .dispatch('inventory/fetchInventoryItems', {
          item_type: 'Tire',
        })
        .then(response => {
          this.inventories = this.fetchInventoryItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteInventoryTireList(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inventory/deleteInventoryItem`, {
                inv_id: id,
              })
              .then(response => {
                this.getInventoryItems()
              })
          }
        })
    },

    editBrandModal: function(brand) {
      this.brand = Object.assign({}, brand)
      this.showEditBrandDialog = true
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchInventoryItems: 'inventory/fetchInventoryItems',
    }),
  },
}
</script>

<style></style>
