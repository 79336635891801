<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="inventories"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(inventory, index1) in items" :key="index1">
            <td>{{ inventory.description }}</td>
            <td>{{ inventory.notes }}</td>
            <td>
              <div v-for="rate in inventory.rates" :key="rate.rate_id">
                <span
                  ><v-icon size="20" color="primary" @click.prevent="editRateModal(rate)">{{ icons.mdiPencil }}</v-icon>
                  {{ rate.name }} ({{ rate.rates }})</span
                >
              </div>
            </td>
            <td>{{ inventory.hour }}</td>
            <td>{{ inventory.retail_price }}</td>
            <td>{{ inventory.discount }}</td>
            <td>
              <div v-for="(inventoryCategory, index2) in inventory.inventory_categories" :key="index2">
                <span
                  ><v-icon size="20" color="primary" @click.prevent="editInventoryCategoryModal(inventoryCategory)">{{
                    icons.mdiPencil
                  }}</v-icon>
                  {{ inventoryCategory.name }}</span
                >
              </div>
            </td>
            <td>{{ formatDate(inventory.created_at) }}</td>
            <td>
              <v-btn
                color="primary"
                @click.prevent="$router.push({ name: 'editlabor_inventory', params: { id: inventory.inv_id } })"
                class="mx-1"
                fab
                x-small
                dark
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
              <v-btn @click.prevent="deleteInventoryPartList(inventory.inv_id)" color="error" fab x-small dark>
                <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getInventoryItems"> Reset </v-btn>
      </template>
    </v-data-table>
    <edit-rate
      :dialog.sync="showEditRateDialog"
      :rate="rate"
      @open-dialog="showEditRateDialog = true"
      @close-dialog="showEditRateDialog = false"
    />

    <edit-inventorycategory
      :dialog.sync="showEditInventoryCategoryDialog"
      :inventoryCategory="inventoryCategory"
      @open-dialog="showEditInventoryCategoryDialog = true"
      @close-dialog="showEditInventoryCategoryDialog = false"
    />
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import EditRateDialog from '@/views/settings/rate/EditRate.vue'
import EditInventoryCategory from '@/views/inventorycategory/EditInventoryCategory.vue'

import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiPencil,
  mdiDeleteForeverOutline,
  mdiAccountHardHat,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    'edit-rate': EditRateDialog,
    'edit-inventorycategory': EditInventoryCategory,
  },
  created: function () {
    this.getInventoryItems()
    window.mitt.on('loadInventoryItems', () => {
      this.getInventoryItems()
    })
  },

  data() {
    return {
      headers: [
        { text: 'Description', value: 'description' },
        { text: 'Note', value: 'notes' },
        { text: 'Rate', value: '' },
        { text: 'Hours', value: 'hour' },
        { text: 'Retail Price', value: 'retail_price' },
        { text: 'Discount', value: 'discount' },
        { text: 'Inventory Category', value: 'name' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      inventories: [],
      search: '',
      inventoryCategory: {},
      rate: {},
      showEditInventoryCategoryDialog: false,
      showNewRateDialog: false,
      showEditRateDialog: false,
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiPencil,
        mdiAccountHardHat,
      },
    }
  },
  methods: {
    getInventoryItems: function () {
      this.$store
        .dispatch('inventory/fetchInventoryItems', {
          item_type: 'Labor',
        })
        .then(response => {
          this.inventories = this.fetchInventoryItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteInventoryPartList(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inventory/deleteInventoryItem`, {
                inv_id: id,
              })
              .then(response => {
                this.getInventoryItems()
              })
          }
        })
    },
    editRateModal: function (rate) {
      this.rate = Object.assign({}, rate)
      this.showEditRateDialog = true
    },

    editInventoryCategoryModal: function (inventoryCategory) {
      this.inventoryCategory = Object.assign({}, inventoryCategory)
      this.showEditInventoryCategoryDialog = true
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchInventoryItems: 'inventory/fetchInventoryItems',
    }),
  },
}
</script>

<style>
</style>